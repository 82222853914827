<template>
   <div class="container">
      <el-container class="wrap">
      <!-- 头部 -->
      <el-header >
        <Header />
      </el-header>
      <!-- 中间 -->
      <el-main class="mains">
         <!-- 模考提示 -->
          <div class="main_content mockBox" v-if="!is_error">
            <img src="../assets/image/mock.png" alt="" class="mockimg m-b-40">
            <p class="font-30 text-bold m-b-20">开始模考</p>
            <p class="font-16 m-b-34">考试途中退出，不返还模考次数</p>
            <div class="mock_opera">
              <div class="btn m-r-34" @click="goBack()">退出</div>
              <div class="btn btn2" @click="goStudy()">开始模考</div>
            </div>
          </div>
          <!-- 设备异常 -->
          <div class="main_content mockBox" v-else>
            <img src="../assets/image/pc.png" alt="" class="pcimg m-b-40">
            <p class="font-30 text-bold m-b-20">您当前设备存在问题，无法进行模考！</p>
            <p class="font-16 m-b-20">请先解决以下问题，然后重新开始测试:</p>
            <p class="font-16 c-60">确保您的电脑已安装麦克风，并且允许 紫蝶教育 有访问的权限。</p>
            <p class="font-16 c-60 m-b-40">确保您的电脑已安装网络摄像头，并且允许 紫蝶教育 有访问的权限。</p>
            <div class="mock_opera">
              <div class="btn btn3">退出</div>
            </div>
          </div>
      </el-main>
    </el-container>
   </div>
</template>
<script>
export default {
   data() {
      return {
        is_error: false
      }
   },
   methods: {
    goBack() {
      this.$router.go(-1);
    },
    goStudy() {
      this.$router.push('/study')
    },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
}
.el-header {
  height: 70px!important;
}

.mains {
  height: calc(100% - 70px);
  padding-top: 40px;
  box-sizing: border-box;
  background: #F6F7FB;
}

.mockBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  padding: 100px 0;
  .mockimg {
    width: 120px;
    height: 120px;
  }
  .mock_opera {
    display: flex;
    .btn {
      width: 110px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      font-size: 14px;
      color: #606882;
      border: 1px solid #DDDFE4;
      box-sizing: border-box;
    }
    .btn2 {
      background: $color-title2;
      border: none!important;
      color: #fff!important;
    }
    .btn3 {
      border: 1px solid $color-title2!important;
      color: $color-title2!important;
    }
  }
}
</style>
